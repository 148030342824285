// Here you can add other styles
.card-header-button {
    width: fit-content;
    padding: .5rem 2rem;
    font-size: 1.2rem;
    font-weight: 500;
}

.card-action-button {
    width: fit-content;
    padding: .5rem 2rem;
    font-size: 1rem;
    font-weight: bold;
}

.card-header-text {
    font-size: 1.5em;
    font-weight: 500;
}

.details-status-badge {
    width: fit-content;
    padding: .65rem 1.6rem;
    font-size: 1.1rem;
}

.app-table-content-list {
    text-align: start;
    vertical-align: middle;
}

.app-table-header {
    text-align: start;
}

.react-datepicker {
    font-size: 1rem !important;
    border: 1px solid #AEAEAE !important;
}

.react-datepicker__header {
    padding-top: 0.8rem !important;
}

.react-datepicker__month {
    margin: 1rem 2rem !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
    width: 2.25rem !important;
    line-height: 2.25rem !important;
    margin: 0.25rem !important;
}

.react-datepicker__current-month {
    font-size: 1.25rem !important;
    padding-bottom: 0.5rem !important;
}

.react-datepicker__navigation {
    top: 0.9rem !important;
}

.react-datepicker__navigation--previous {
    left: 1rem !important;
}

.react-datepicker__navigation--next {
    right: 1rem !important;
}

.react-datepicker__day--highlighted-custom-1 {
    color: white !important;
    background-color: #32BE3F !important;
    border-radius: 0.3rem !important;
}

.react-datepicker__day--highlighted-custom-2 {
    color: white !important;
    background-color: #AEAEAE !important;
    border-radius: 0.3rem !important;
}

.react-datepicker__today-button {
    padding: 0.5rem 0 !important;
}

.react-datepicker__month-select {
    padding: 0.3rem 0.3rem 0.2rem 0.3rem !important;
}

.react-datepicker__year-select {
    padding: 0.3rem 0.3rem 0.2rem 0.3rem !important;
}

.input-custom-date-picker {
    padding: 0.35rem 0.35rem 0.35rem 0.55rem !important;
    border: 1px solid #B1B7C1;
    border-radius: 0.25rem;
    width: 38.5rem;
}

.custom-list ul,
ol {
    list-style: revert;
    margin: 10px;
    padding-left: 20px
}